import '/assets/styles/partials/loader.scss';
import PropTypes from 'prop-types'
import { Loader } from '@wearetla/tla-essentials-tools/partials/loader';

const StyledLoader = ({ solid, dark, small, className: classNameProp = '', ...props }) => {
	const className = `${classNameProp}${solid ? ' solid' : ''}${dark ? ' dark' : ''}${small ? ' small' : ''}`;
	return (
		<Loader {...props} className={className} />
	)
}

StyledLoader.propTypes = {
	solid: PropTypes.bool,
	dark: PropTypes.bool,
	small: PropTypes.bool,
	className: PropTypes.string,
}

export default StyledLoader;